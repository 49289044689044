/*******************************************************
 * Custom theme code styles
 * Written by Themelize.me (http://themelize.me)
 *
 * Includes the base variables & mixins needed for all
 * scss files
 *******************************************************/
/*---------------------------------------------------------------------------
 // 
 // Custom theme code styles
 // Written by Themelize.me (http://themelize.me)
 // 
 // This is an empty starter template for overriding styles
 // set by Bootstrap & the theme
 // 
 // ----------------------------------------------------
 // 
 // Remove unused code for better performances
 // 
 // ----------------------------------------------------
 // 
 // $see - Usefuls tools online for editing
 // 1. http://charliepark.org/bootstrap_buttons/ - Button style generator
 // 2. http://www.colorzilla.com/gradient-editor/ - CSS3 gradient maker
 // 
 // $note
 // To ensure custom styles are picked up
 // wrap definitions in body tag
 // ie.
 // body .navbar-inner {
 //   background: #ff0000;
 // }
 // 
 ---------------------------------------------------------------------------*/
body a {
  color: #00ADBB;
  text-decoration: none;
}

body a:hover {
  color: #007e88;
  text-decoration: underline;
}

body #header-hidden-link a.show-hide,
body .flexslider-wrapper .flex-control-nav li a.flex-active,
body .static-banner .flex-control-nav li a.flex-active {
  background: #007e88;
  /* primary colour */
}

body .flex-direction-nav a,
body .flex-direction-nav a:before {
  color: #00ADBB !important;
}

body .header {
  border-bottom-color: #00ADBB;
  /* primary colour */
}

body .header .navbar-brand {
  color: #00ADBB;
  /* primary colour */
}

body .stats .stat-header {
  background: #00ADBB;
  background-image: radial-gradient(circle, #00adbb, #007e88);
  background-repeat: no-repeat;
}

body .stats .stat-header:after {
  border-top-color: #00ADBB;
  /* primary colour */
}

.slider-appstrap-theme .tp-bullets li a:hover,
.slider-appstrap-theme .tp-bullets li a.flex-active,
.slider-appstrap-theme .tp-bullets li a.selected,
.slider-appstrap-theme .tp-bullets .bullet:hover,
.slider-appstrap-theme .tp-bullets .bullet.flex-active,
.slider-appstrap-theme .tp-bullets .bullet.selected,
.slider-appstrap-theme .tp-bullets.round li a:hover,
.slider-appstrap-theme .tp-bullets.round li a.flex-active,
.slider-appstrap-theme .tp-bullets.round li a.selected,
.slider-appstrap-theme .tp-bullets.round .bullet:hover,
.slider-appstrap-theme .tp-bullets.round .bullet.flex-active,
.slider-appstrap-theme .tp-bullets.round .bullet.selected,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a:hover,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a.flex-active,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a.selected,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet:hover,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet.flex-active,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet.selected,
.flex-control-nav li a:hover,
.flex-control-nav li a.flex-active,
.flex-control-nav li a.selected,
.flex-control-nav .bullet:hover,
.flex-control-nav .bullet.flex-active,
.flex-control-nav .bullet.selected {
  background: #00ADBB;
}

.slider-appstrap-theme .tp-bannertimer {
  background: #00ADBB;
}

.bootstrap-switch>div>span.bootstrap-switch-primary {
  background: #00ADBB;
}

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #00ADBB;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.dropdown-menu-primary {
  border-bottom-color: #00ADBB !important;
}

.dropdown-menu-primary a:hover:before {
  color: #00ADBB !important;
}

.timeline-item {
  border-bottom-color: #00ADBB;
}

.timeline-item.tag-featured:after,
.timeline-item.marker-highlight:after,
.timeline-item.highlight:after {
  color: #00ADBB;
}

.owl-controls.clickable .owl-buttons div:hover,
.owl-controls .owl-page.active span,
.owl-controls.clickable .owl-page:hover span {
  background: #00ADBB;
}

.navbar-border-bottom-effect .nav-link:hover:after,
.navbar-border-bottom-effect .nav-link:active:after,
.navbar-border-bottom-effect .nav-link:focus:after,
.navbar-border-bottom-effect .nav-link.active:after {
  background: #00ADBB;
}

body .is-sticky .sticky-bg-primary {
  background-color: #00ADBB !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-0,
body .is-sticky .bg-primary.sticky-bg-op-0 {
  background-color: rgba(0, 173, 187, 0) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-1,
body .is-sticky .bg-primary.sticky-bg-op-1 {
  background-color: rgba(0, 173, 187, 0.1) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-2,
body .is-sticky .bg-primary.sticky-bg-op-2 {
  background-color: rgba(0, 173, 187, 0.2) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-3,
body .is-sticky .bg-primary.sticky-bg-op-3 {
  background-color: rgba(0, 173, 187, 0.3) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-4,
body .is-sticky .bg-primary.sticky-bg-op-4 {
  background-color: rgba(0, 173, 187, 0.4) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-5,
body .is-sticky .bg-primary.sticky-bg-op-5 {
  background-color: rgba(0, 173, 187, 0.5) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-6,
body .is-sticky .bg-primary.sticky-bg-op-6 {
  background-color: rgba(0, 173, 187, 0.6) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-7,
body .is-sticky .bg-primary.sticky-bg-op-7 {
  background-color: rgba(0, 173, 187, 0.7) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-8,
body .is-sticky .bg-primary.sticky-bg-op-8 {
  background-color: rgba(0, 173, 187, 0.8) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-9,
body .is-sticky .bg-primary.sticky-bg-op-9 {
  background-color: rgba(0, 173, 187, 0.9) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-10,
body .is-sticky .bg-primary.sticky-bg-op-10 {
  background-color: #00adbb !important;
}

body .is-sticky .sticky-text-primary {
  color: #00ADBB !important;
}

body .is-sticky .sticky-border-primary {
  border: 1px solid transparent;
  border-color: #00ADBB !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-0,
body .is-sticky .border-primary.sticky-border-op-0 {
  border-color: rgba(0, 173, 187, 0) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-1,
body .is-sticky .border-primary.sticky-border-op-1 {
  border-color: rgba(0, 173, 187, 0.1) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-2,
body .is-sticky .border-primary.sticky-border-op-2 {
  border-color: rgba(0, 173, 187, 0.2) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-3,
body .is-sticky .border-primary.sticky-border-op-3 {
  border-color: rgba(0, 173, 187, 0.3) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-4,
body .is-sticky .border-primary.sticky-border-op-4 {
  border-color: rgba(0, 173, 187, 0.4) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-5,
body .is-sticky .border-primary.sticky-border-op-5 {
  border-color: rgba(0, 173, 187, 0.5) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-6,
body .is-sticky .border-primary.sticky-border-op-6 {
  border-color: rgba(0, 173, 187, 0.6) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-7,
body .is-sticky .border-primary.sticky-border-op-7 {
  border-color: rgba(0, 173, 187, 0.7) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-8,
body .is-sticky .border-primary.sticky-border-op-8 {
  border-color: rgba(0, 173, 187, 0.8) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-9,
body .is-sticky .border-primary.sticky-border-op-9 {
  border-color: rgba(0, 173, 187, 0.9) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-10,
body .is-sticky .border-primary.sticky-border-op-10 {
  border-color: #00adbb !important;
}

.card-primary {
  border-color: #00ADBB;
  background-color: #00ADBB;
}

.card-outline-primary {
  border-color: #00ADBB;
}

.card.clickable-element:hover {
  border: 1px solid #00ADBB;
}

.tag-primary,
.badge-primary,
.bg-primary {
  background-color: #00ADBB !important;
}

.bg-primary-bright {
  background-color: #00adbb !important;
}

.btn-link,
.btn-link:hover {
  color: #00ADBB;
}

.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: #00ADBB !important;
}

.text-gray {
  color: #929292 !important;
}
.btn-primary {
  color: #fff;
  background-color: #00ADBB;
  border-color: #0095a2;
  color: white;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #007e88;
  border-color: #001f22;
}

.btn-primary:hover {
  color: #fff;
  background-color: #007e88;
  border-color: #005d64;
}

.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle,
.open>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #007e88;
  border-color: #005d64;
  background-image: none;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.show>.btn-primary.dropdown-toggle:hover,
.show>.btn-primary.dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle.focus,
.open>.btn-primary.dropdown-toggle:hover,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #005d64;
  border-color: #001f22;
}

.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary:disabled,
fieldset[disabled] .btn-primary {
  background-color: #00ADBB;
  border-color: #0095a2;
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #00ADBB;
  border-color: #0095a2;
}

.btn-primary .badge {
  color: #00ADBB;
  background-color: #fff;
}

.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #00ADBB;
  border-color: #00ADBB;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.disabled:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary:disabled:hover,
.btn-outline-primary:disabled:focus {
  color: white;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary.active:focus,
.btn-outline-primary:active:focus,
.show>.btn-outline-primary.dropdown-toggle,
.open>.btn-outline-primary.dropdown-toggle {
  background-color: #00ADBB;
  border-color: #00ADBB;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-item.show .nav-link:focus,
.nav-pills .nav-item.show .nav-link:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
  background-color: #00ADBB;
  color: white;
}

.nav-pills-border-bottom .nav-link.active:after,
.nav-pills-border-bottom-inside .nav-link.active:after {
  background-color: #00ADBB;
}

.nav-pills.nav-pills-border-bottom .nav-link:hover,
.nav-pills.nav-pills-border-bottom .nav-link:hover:focus,
.nav-pills.nav-pills-border-bottom .nav-link.active,
.nav-pills.nav-pills-border-bottom .nav-link.active:focus,
.nav-pills.nav-pills-border-bottom-inside .nav-link.active,
.nav-pills.nav-pills-border-bottom-inside .nav-link.active:focus,
.nav-tabs-flat .nav-link:hover,
.nav-tabs-flat .nav-link:hover:focus {
  color: #00ADBB !important;
}

.nav-tabs-flat .nav-link.active:hover {
  color: white !important;
}

.list-group-item-primary,
a.list-group-item-primary,
button.list-group-item-primary {
  background-color: rgba(85, 167, 154, 0.25);
  color: #33635c;
}

a.list-group-item-primary:hover,
a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  color: #33635c;
  background-color: rgba(85, 167, 154, 0.45);
}

.nav-section-menu .nav .nav-link:hover i,
.nav-section-menu.nav .nav-link:hover i,
.nav-section-menu .nav .nav-link.active i,
.nav-section-menu.nav .nav-link.active i,
.nav-section-menu .nav .nav-link.active:hover i,
.nav-section-menu.nav .nav-link.active:hover i,
.nav-section-menu .nav .active .nav-link i,
.nav-section-menu.nav .active .nav-link i,
.nav-section-menu .nav .active .nav-link:hover i,
.nav-section-menu.nav .active .nav-link:hover i {
  color: #00ADBB !important;
}

.nav-section-menu .nav .nav-link.active,
.nav-section-menu.nav .nav-link.active,
.nav-section-menu .nav .nav-link.active:hover,
.nav-section-menu.nav .nav-link.active:hover,
.nav-section-menu .nav .active .nav-link,
.nav-section-menu.nav .active .nav-link,
.nav-section-menu .nav .active .nav-link:hover,
.nav-section-menu.nav .active .nav-link:hover {
  border-left-color: #00ADBB;
  color: #00ADBB;
}

@media (min-width: 768px) {

  .nav-section-menu.nav-section-menu-right .nav .nav-link.active,
  .nav-section-menu.nav-section-menu-right.nav .nav-link.active,
  .nav-section-menu.nav-section-menu-right .nav .nav-link.active:hover,
  .nav-section-menu.nav-section-menu-right.nav .nav-link.active:hover,
  .nav-section-menu.nav-section-menu-right .nav .active .nav-link,
  .nav-section-menu.nav-section-menu-right.nav .active .nav-link,
  .nav-section-menu.nav-section-menu-right .nav .active .nav-link:hover,
  .nav-section-menu.nav-section-menu-right.nav .active .nav-link:hover {
    border-right-color: #00ADBB;
  }
}

.nav-tabs.nav-stacked .active>.nav-link i,
.nav-tabs.nav-stacked .active>.nav-link:hover i,
.nav-tabs.nav-stacked .nav-link.active i,
.nav-tabs.nav-stacked .nav-link.active:hover i,
.nav-tabs .active>.nav-link,
.nav-tabs .active>.nav-link:hover,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover {
  color: #00ADBB;
}

.nav-tabs .active>.nav-link:before,
.nav-tabs .nav-link.active:before {
  background: #00ADBB;
}

.page-link,
.page-link:hover {
  color: #00ADBB;
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  background: #00ADBB;
  border-color: #00ADBB;
}

.nav-steps .nav-link.active,
.nav-steps .nav-link.active:hover {
  background: #00ADBB;
  border-color: #00ADBB;
}

.link-effect-underline:after {
  background-color: #00ADBB;
}

.link-effect-slideup .nav-link-inner:after {
  color: #00ADBB;
}

.progress[value]::-webkit-progress-value {
  background-color: #00ADBB;
}

.progress[value]::-ms-fill {
  background-color: #00ADBB;
}

.progress[value]::-moz-progress-bar {
  background-color: #00ADBB;
}

.progress-bar {
  background-color: #00ADBB;
}

.alert-primary {
  background-color: rgba(0, 173, 187, 0.25);
  border-color: rgba(0, 173, 187, 0.8);
  color: #004f55;
}

.alert-primary hr {
  border-top-color: rgba(85, 167, 154, 0.85);
}

.alert-primary .alert-link {
  color: #004f55;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #00ADBB;
  border-color: #00ADBB;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: rgba(255, 255, 255, 0.9);
}

.blockquote {
  border-left-color: #00ADBB;
}

.blockquote-reverse {
  border-right-color: #00ADBB;
}

.tag-cloud a.tag {
  color: #00ADBB;
}

@media (min-width: 576px) {
  .card-group .card+.card-outline-primary {
    border-left: 1px solid #00ADBB;
  }
}

@media (min-width: 576px) {

  .navbar-expand.navbar-main.collapse .dropdown-menu,
  .navbar-expand .navbar-main.collapse .dropdown-menu {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand.navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand .navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before {
    color: #00ADBB !important;
  }

  .navbar-expand.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand.navbar-main .nav-link-dd-effect:hover,
  .navbar-expand.navbar-main .nav-link-dd-effect.active,
  .navbar-expand .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand .navbar-main .nav-link-dd-effect:hover,
  .navbar-expand .navbar-main .nav-link-dd-effect.active {
    border-top-color: #00ADBB !important;
  }
}

@media (min-width: 768px) {

  .navbar-expand-sm.navbar-main.collapse .dropdown-menu,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-sm.navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-sm .navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before {
    color: #00ADBB !important;
  }

  .navbar-expand-sm.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-sm.navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-sm.navbar-main .nav-link-dd-effect.active,
  .navbar-expand-sm .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-sm .navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-sm .navbar-main .nav-link-dd-effect.active {
    border-top-color: #00ADBB !important;
  }
}

@media (min-width: 992px) {

  .navbar-expand-md.navbar-main.collapse .dropdown-menu,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-md.navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-md .navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before {
    color: #00ADBB !important;
  }

  .navbar-expand-md.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-md.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-md.navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-md.navbar-main .nav-link-dd-effect.active,
  .navbar-expand-md .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-md .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-md .navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-md .navbar-main .nav-link-dd-effect.active {
    border-top-color: #00ADBB !important;
  }
}

@media (min-width: 1200px) {

  .navbar-expand-lg.navbar-main.collapse .dropdown-menu,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after {
    border-bottom-color: #00ADBB !important;
  }

  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-lg.navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
  .navbar-expand-lg .navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before {
    color: #00ADBB !important;
  }

  .navbar-expand-lg.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-lg.navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-lg.navbar-main .nav-link-dd-effect.active,
  .navbar-expand-lg .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .show>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .show.active>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .open>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .navbar-nav .open.active>.dropdown-toggle,
  .navbar-expand-lg .navbar-main .nav-link-dd-effect:hover,
  .navbar-expand-lg .navbar-main .nav-link-dd-effect.active {
    border-top-color: #00ADBB !important;
  }
}

.navbar-expand-xl.navbar-main.collapse .dropdown-menu,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu {
  border-bottom-color: #00ADBB !important;
}

.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-submenu .dropdown-item:after {
  border-bottom-color: #00ADBB !important;
}

.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
.navbar-expand-xl.navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-item:hover:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-item:focus:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-item.active:hover:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-item.active:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .dropdown-item.active:focus:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .active>.dropdown-item:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .active>.dropdown-item:hover:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .active>.dropdown-item:focus:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .show>.dropdown-item:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .show>.dropdown-item:hover:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .show>.dropdown-item:focus:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .open>.dropdown-item:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .open>.dropdown-item:hover:before,
.navbar-expand-xl .navbar-main.collapse .dropdown-menu .open>.dropdown-item:focus:before {
  color: #00ADBB !important;
}

.navbar-expand-xl.navbar-main .navbar-nav .show>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .show.active>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .show>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .show.active>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .open>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .open.active>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .open>.dropdown-toggle,
.navbar-expand-xl.navbar-main .navbar-nav .open.active>.dropdown-toggle,
.navbar-expand-xl.navbar-main .nav-link-dd-effect:hover,
.navbar-expand-xl.navbar-main .nav-link-dd-effect.active,
.navbar-expand-xl .navbar-main .navbar-nav .show>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .show.active>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .show>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .show.active>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .open>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .open.active>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .open>.dropdown-toggle,
.navbar-expand-xl .navbar-main .navbar-nav .open.active>.dropdown-toggle,
.navbar-expand-xl .navbar-main .nav-link-dd-effect:hover,
.navbar-expand-xl .navbar-main .nav-link-dd-effect.active {
  border-top-color: #00ADBB !important;
}

.navbar-border-bottom-effect .nav-link:hover:after,
.navbar-border-bottom-effect .nav-link:active:after,
.navbar-border-bottom-effect .nav-link:focus:after,
.navbar-border-bottom-effect .nav-link.active:after {
  background: #00ADBB;
}

.form-control:focus,
.form-control:focus:hover {
  border-color: #00ADBB;
}

.btn-primary.btn-invert {
  background-color: #007e88;
}

.btn-primary.btn-invert:hover,
.btn-primary.btn-invert:focus {
  background-color: #00ADBB;
}

.text-primary-faded {
  color: rgba(0, 173, 187, 0.3) !important;
}

.text-primary-darkend {
  color: #004f55 !important;
}

.bg-primary-faded {
  background-color: rgba(0, 173, 187, 0.3) !important;
}

.bg-primary-darkend {
  background-color: #004f55 !important;
}

.border-primary {
  border-color: #00ADBB !important;
}

.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.3) !important;
}

.border-primary-darkend {
  border-color: #004f55 !important;
}

.bg-op-0.bg-primary {
  background-color: rgba(0, 173, 187, 0) !important;
}

.bg-op-0.border-primary-faded {
  background-color: rgba(0, 173, 187, 0) !important;
}

.bg-op-0.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0) !important;
}

.border-op-0.border-primary {
  border-color: rgba(0, 173, 187, 0) !important;
}

.border-op-0.border-primary-faded {
  border-color: rgba(0, 173, 187, 0) !important;
}

.border-op-0.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0) !important;
}

.bg-op-1.bg-primary {
  background-color: rgba(0, 173, 187, 0.1) !important;
}

.bg-op-1.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.1) !important;
}

.bg-op-1.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.1) !important;
}

.border-op-1.border-primary {
  border-color: rgba(0, 173, 187, 0.1) !important;
}

.border-op-1.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.1) !important;
}

.border-op-1.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.1) !important;
}

.bg-op-2.bg-primary {
  background-color: rgba(0, 173, 187, 0.2) !important;
}

.bg-op-2.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.2) !important;
}

.bg-op-2.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.2) !important;
}

.border-op-2.border-primary {
  border-color: rgba(0, 173, 187, 0.2) !important;
}

.border-op-2.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.2) !important;
}

.border-op-2.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.2) !important;
}

.bg-op-3.bg-primary {
  background-color: rgba(0, 173, 187, 0.3) !important;
}

.bg-op-3.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.3) !important;
}

.bg-op-3.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.3) !important;
}

.border-op-3.border-primary {
  border-color: rgba(0, 173, 187, 0.3) !important;
}

.border-op-3.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.3) !important;
}

.border-op-3.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.3) !important;
}

.bg-op-4.bg-primary {
  background-color: rgba(0, 173, 187, 0.4) !important;
}

.bg-op-4.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.4) !important;
}

.bg-op-4.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.4) !important;
}

.border-op-4.border-primary {
  border-color: rgba(0, 173, 187, 0.4) !important;
}

.border-op-4.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.4) !important;
}

.border-op-4.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.4) !important;
}

.bg-op-5.bg-primary {
  background-color: rgba(0, 173, 187, 0.5) !important;
}

.bg-op-5.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.5) !important;
}

.bg-op-5.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.5) !important;
}

.border-op-5.border-primary {
  border-color: rgba(0, 173, 187, 0.5) !important;
}

.border-op-5.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.5) !important;
}

.border-op-5.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.5) !important;
}

.bg-op-6.bg-primary {
  background-color: rgba(0, 173, 187, 0.6) !important;
}

.bg-op-6.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.6) !important;
}

.bg-op-6.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.6) !important;
}

.border-op-6.border-primary {
  border-color: rgba(0, 173, 187, 0.6) !important;
}

.border-op-6.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.6) !important;
}

.border-op-6.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.6) !important;
}

.bg-op-7.bg-primary {
  background-color: rgba(0, 173, 187, 0.7) !important;
}

.bg-op-7.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.7) !important;
}

.bg-op-7.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.7) !important;
}

.border-op-7.border-primary {
  border-color: rgba(0, 173, 187, 0.7) !important;
}

.border-op-7.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.7) !important;
}

.border-op-7.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.7) !important;
}

.bg-op-8.bg-primary {
  background-color: rgba(0, 173, 187, 0.8) !important;
}

.bg-op-8.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.8) !important;
}

.bg-op-8.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.8) !important;
}

.border-op-8.border-primary {
  border-color: rgba(0, 173, 187, 0.8) !important;
}

.border-op-8.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.8) !important;
}

.border-op-8.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.8) !important;
}

.bg-op-9.bg-primary {
  background-color: rgba(0, 173, 187, 0.9) !important;
}

.bg-op-9.border-primary-faded {
  background-color: rgba(0, 173, 187, 0.9) !important;
}

.bg-op-9.border-primary-darkend {
  background-color: rgba(0, 79, 85, 0.9) !important;
}

.border-op-9.border-primary {
  border-color: rgba(0, 173, 187, 0.9) !important;
}

.border-op-9.border-primary-faded {
  border-color: rgba(0, 173, 187, 0.9) !important;
}

.border-op-9.border-primary-darkend {
  border-color: rgba(0, 79, 85, 0.9) !important;
}

.bg-op-10.bg-primary {
  background-color: #00adbb !important;
}

.bg-op-10.border-primary-faded {
  background-color: #00adbb !important;
}

.bg-op-10.border-primary-darkend {
  background-color: #004f55 !important;
}

.border-op-10.border-primary {
  border-color: #00adbb !important;
}

.border-op-10.border-primary-faded {
  border-color: #00adbb !important;
}

.border-op-10.border-primary-darkend {
  border-color: #004f55 !important;
}

hr.hr-primary {
  border-top-color: #00ADBB !important;
}

hr.hr-primary-faded {
  border-top-color: rgba(0, 173, 187, 0.3) !important;
}

hr.hr-primary-darkend {
  border-top-color: #004f55 !important;
}

.header .header-brand-text {
  color: #00ADBB;
}

.price-banner {
  color: #00ADBB;
}

.focus-box {
  border-bottom-color: #00ADBB !important;
}

.blog-grid .blog-post {
  border-bottom-color: #00ADBB !important;
}

.page-loader {
  background: #00ADBB !important;
}

.owl-nav div:hover,
.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #00ADBB !important;
}

.modal-progress {
  background: #00ADBB !important;
}

.overlay.overlay-primary:after,
.overlay-hover-content.overlay-primary:after {
  background: #00ADBB !important;
}

.overlay.overlay-primary .overlay-header:after,
.overlay.overlay-primary .overlay-footer:before,
.overlay-hover-content.overlay-primary .overlay-header:after,
.overlay-hover-content.overlay-primary .overlay-footer:before {
  background: #eefeff !important;
}

.header-primary .header {
  background: #00ADBB;
}

.header-primary .header .header-inner .header-divider:after {
  background-color: #09edff;
}

.header-transparent .header.is-sticky,
.header-transparent .is-sticky .header {
  border-bottom-color: #00ADBB;
}

.header-transparent.header-primary .header {
  background: transparent;
}

.header-transparent.header-primary .header.is-sticky,
.header-transparent.header-primary .is-sticky .header {
  background-color: rgba(0, 173, 187, 0.98);
  border-bottom-color: #292b2c;
}

.header-translucent.header-dark .header.is-sticky,
.header-translucent.header-dark .is-sticky .header {
  border-bottom-color: rgba(0, 173, 187, 0.4);
}

.header-translucent.header-primary .header {
  background-color: rgba(0, 173, 187, 0.2);
  border-bottom-color: rgba(0, 173, 187, 0.6);
}

.header-translucent.header-primary .header.is-sticky,
.header-translucent.header-primary .is-sticky .header {
  background-color: rgba(0, 173, 187, 0.95);
  border-bottom-color: #292b2c;
}

.divider-primary.divider-diagonal:before {
  border-left-color: #00ADBB;
}

.divider-primary.divider-diagonal-r:before {
  border-right-color: #00ADBB;
  border-left-color: transparent;
}

.divider-primary.divider-arrow-b:before {
  border-top-color: #00ADBB;
}

.divider-primary.divider-arrow-t:before {
  border-bottom-color: #00ADBB;
}

.divider-primary.divider-arrow-l:before {
  border-right-color: #00ADBB;
}

.divider-primary.divider-arrow-r:before {
  border-left-color: #00ADBB;
}

.list-timeline.list-timeline-primary:before {
  background: #00ADBB;
}

.list-timeline.list-timeline-primary .list-timeline-item:before {
  border-color: #00ADBB;
}

.list-timeline.list-timeline-primary .list-timeline-item.active:before,
.list-timeline.list-timeline-primary .list-timeline-item.show:before {
  background: #00ADBB;
}